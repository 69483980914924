<template>

    <GanttElastic 
        :tasks="tasks" 
        :options="options" 
        @options-changed="optionsUpdate"
        @dynamic-style-changed="styleUpdate">
        <GanttHeader slot="header">ceva</GanttHeader>
    </GanttElastic>     
  
</template>

<script>
    import BasePage from '@/pages/BasePage'
    import GanttElastic from "gantt-elastic";
    import GanttHeader from "gantt-elastic-header";
    import dayjs from "dayjs";

    let options = {
        taskMapping: {
            progress: "percent"
        },
        maxRows: 100,
        maxHeight: 1000,
        title: {
            label: "Your project title as html (link or whatever...)",
            html: true
        },
        row: {
            height: 24
        },
        calendar: {
            hour: {
                display: false
            }
        },
        chart: {
            progress: {
                bar: false
            },
            expander: {
                display: true
            }
        },
        taskList: {
            expander: {
                straight: false
            },
            columns: [
                {
                    id: 1,
                    label: "ID",
                    value: "id",
                    width: 40
                },
                {
                    id: 2,
                    label: "Description",
                    value: "label",
                    width: 200,
                    expander: true,
                    html: true
                },
                {
                    id: 3,
                    label: "Assigned to",
                    value: "user",
                    width: 130,
                    html: true
                },
                {
                    id: 4,
                    label: "Start",
                    value: task => dayjs(task.start).format("YYYY-MM-DD"),
                    width: 78
                },
                {
                    id: 5,
                    label: "Type",
                    value: "type",
                    width: 68
                },
                {
                    id: 6,
                    label: "%",
                    value: "progress",
                    width: 35,
                    style: {
                        "task-list-header-label": {
                            "text-align": "center",
                            width: "100%"
                        },
                        "task-list-item-value-container": {
                            "text-align": "center",
                            width: "100%"
                        }
                    }
                }
            ]
        },
        locale: {
            name: "en",
            Now: "Now",
            "X-Scale": "Zoom-X",
            "Y-Scale": "Zoom-Y",
            "Task list width": "Task list",
            "Before/After": "Expand",
            "Display task list": "Task list"
        }
    };

    export default {
        name: "Grafic_gantt", 
        components:{
            GanttElastic,
            GanttHeader
        },    
        extends: BasePage,
        props: {
            tasks: {
                type: Array
            }
        },
        data(){
            return {
                // tasks:[],
                options,
                dynamicStyle: {},
                lastId: 16
            }
        },

        methods: {           
            tasksUpdate(tasks) {
                this.tasks = tasks;
            },
            optionsUpdate(options) {
                this.options = options;
            },
            styleUpdate(style) {
                this.dynamicStyle = style;
            }
        },
                       
        mounted: function () {
            // gantt.config.xml_date = "%Y-%m-%d";
            // gantt.init(this.$refs.gantt);
            // gantt.parse(this.$props.tasks);
        }
    }
</script>

<style lang="less">


</style>
